import React from 'react';
import {HomePage} from '../../components/Home';
import {NETWORKS} from '../../constants/env';
import {useTransfer} from '../../hooks/Transfer';
import {Modal} from '../../ui-kit/Modal';

export const HomePageContainer = () => {
  const {
    amount,
    handlerChangeAmount,
    swapNetwork,
    firstNetwork,
    secondNetwork,
    transfer,
    errorState,
    handlerSetNetwork,
    closeViewMode,
    balance,
    transaction,
    treatmentChains,
  } = useTransfer();
  if (!firstNetwork || !secondNetwork)
    return <Modal notAvailable={!NETWORKS?.length} treatmentChains={treatmentChains} />;
  return (
    <HomePage
      balance={balance}
      transfer={transfer}
      changeNetwork={handlerSetNetwork}
      errorState={errorState}
      amount={amount}
      changeAmount={handlerChangeAmount}
      closeViewMode={closeViewMode}
      firstNetwork={firstNetwork}
      secondNetwork={secondNetwork}
      swapNetwork={swapNetwork}
      transaction={transaction}
    />
  );
};

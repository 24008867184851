import {keyframes} from 'styled-components';

export const fadeout = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
`;

export const ping = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #2EEDFF;
  }
  100% {
    background-color: transparent;
  }
`;

import React from 'react';
import {ModalBackground, Wrapper, FirstRow, CloseImage, TextWrapper, ConnectButton} from './styles';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import {Text} from '../../ui-kit/Typography/Text';
import {ModalPropsT} from './types';

export const Modal: React.FC<ModalPropsT> = ({setError, treatmentChains, notAvailable}) => {
  const handlerClose = () => {
    setError?.('');
  };
  const handlerSwitchNetwork = async () => {
    const requestStatus = await treatmentChains();
    if (requestStatus) {
      setError?.('');
    }
  };
  return (
    <>
      <ModalBackground onClick={handlerClose} />
      <Wrapper>
        <FirstRow>
          {setError && (
            <CloseImage onClick={handlerClose}>
              <CloseIcon />
            </CloseImage>
          )}
        </FirstRow>
        <TextWrapper>
          {notAvailable ? (
            <Text size={22} align={'center'}>
              There is no avaliable networks for transactions. Please, swith to another one
            </Text>
          ) : (
            <Text size={22} align={'center'}>
              Looks like you are on the wrong side of network
            </Text>
          )}
        </TextWrapper>
        {!notAvailable && (
          <TextWrapper>
            <Text size={18} align={'center'}>
              To connect to the Waterwall you need to connect to its network
            </Text>
          </TextWrapper>
        )}
        <ConnectButton value={'Switch to Another network'} type={'button'} onClick={handlerSwitchNetwork} />
      </Wrapper>
    </>
  );
};

import React from 'react';
import {
  MainLayoutContainer,
  ContentMain,
  Title,
  Footer,
  FooterContent,
  HeaderLink,
  AddressHeaderWrapper,
  HeaderWrapper,
  FooterLink,
} from './styles';
import {HeaderPropsT, LayoutPropsT} from './types';
import {Logo} from '../Logo';
import {MAIN_STIE_LINK} from '../../constants/common';
import {Text} from '../Typography/Text';
import {LogOutButton} from '../LogOutButton';

export const MainLayout: React.FC<LayoutPropsT> = ({children, title, userAddress, logOut}) => {
  return (
    <>
      <MainLayoutContainer>
        <Header userAddress={userAddress} logOut={logOut} />
        <ContentMain>
          {title && <Title>{title}</Title>}
          {children}
        </ContentMain>
      </MainLayoutContainer>
      <Footer>
        <FooterContent>
          Powered by Wanchain on the backend, our Bridge ensures secure and seamless cross-chain transactions. Learn
          more about at{' '}
          <FooterLink href="https://www.wanchain.org" target="_blank" rel="noreferrer">
            www.wanchain.org
          </FooterLink>
          .
        </FooterContent>
      </Footer>
    </>
  );
};

const Header: React.FC<HeaderPropsT> = ({userAddress, logOut}) => {
  return (
    <HeaderWrapper>
      <HeaderLink target="_blank" href={MAIN_STIE_LINK}>
        <Logo />
      </HeaderLink>
      {userAddress && (
        <AddressHeaderWrapper>
          <Text variant="secondary">Address:</Text>
          <Text>{userAddress}</Text>
          <LogOutButton onClick={logOut} />
        </AddressHeaderWrapper>
      )}
    </HeaderWrapper>
  );
};

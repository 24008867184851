export const errors = {
  chain: 'Please connect to Waterwall network',
  userReject: 'You need to grant access to your metamask',
  metamask: 'Please install MetaMask',
  incorrectChain: 'Please connect to correct network',
  notEnoughBalance:
    'There are not enough funds in the current network to carry out the transfer. Please top up this exchange for this network',
  unknowError: 'There is something wrong',
  cancelled: 'Transaction cancelled',
  speedUp: 'We are suggesting to speed up the transaction in metamask',
};

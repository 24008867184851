import React, {useState} from 'react';
import {OptionsNetWrapper, DownArrow, Option, SelectBlock, SelectWrapper} from './styles';
import ArrowDown from '../../assets/arrow-down.png';

export interface ISelectInputProps {
  value: string;
  options: {value: string; label: string}[];
  onChange: (value: string) => void;
}

export default function SelectInput({options, onChange, value}: ISelectInputProps) {
  const [open, setisOpen] = useState(false);

  const handlerOpenMenu = (status: boolean) => () => setisOpen(status);

  const handleChange = (value: string) => () => {
    onChange(value);
    handlerOpenMenu(false)();
  };

  return (
    <SelectWrapper>
      <SelectBlock onClick={open ? handlerOpenMenu(false) : handlerOpenMenu(true)}>
        <div>{value}</div>
        <DownArrow src={ArrowDown} theme={{status: !!open}} />
      </SelectBlock>
      {open && (
        <>
          {options?.length ? (
            <OptionsNetWrapper theme={{status: !!open}}>
              {options.map((el) => (
                <Option onClick={handleChange(el.value)} key={el.value}>
                  {el.label}
                </Option>
              ))}
            </OptionsNetWrapper>
          ) : (
            <OptionsNetWrapper theme={{status: !!open}}>
              <Option>No options</Option>
            </OptionsNetWrapper>
          )}
        </>
      )}
    </SelectWrapper>
  );
}

import React from 'react';
import {ReactComponent as Icon} from '../../assets/IconLogo.svg';
import {ReactComponent as Text} from '../../assets/TextLogo.svg';
import {Wrapper} from './styles';

export const Logo = () => (
  <Wrapper>
    <Icon />
    <Text />
  </Wrapper>
);

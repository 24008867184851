import React, {PropsWithChildren, useEffect} from 'react';
import {checkMetaMask} from '../../helper/metamask';
import {useSetRecoilState} from 'recoil';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/route';
import {address, chainId, error} from '../../states/Session';
import {checkPermission, getAccounts} from '../../hooks/Metamask';

declare global {
  interface Window {
    ethereum: any;
  }
}

const MetamaskProvider: React.FC<PropsWithChildren> = ({children}) => {
  const setAddress = useSetRecoilState(address);
  const setChain = useSetRecoilState(chainId);
  const setError = useSetRecoilState(error);
  const history = useHistory();

  const handlerAccount = async (accounts: Array<string>) => {
    if (await checkPermission()) {
      setAddress(accounts[0] || '');
      setError('');
      history.push(route.login);
    }
  };

  // change after choose all network for work
  const handlerChain = async (chainId: string) => {
    const account = await getAccounts();
    setChain(chainId);
    if (account.length > 0) {
      history.push(route.base);
    } else {
      history.push(route.login);
    }
  };

  useEffect(() => {
    if (checkMetaMask()) {
      window.ethereum.on('accountsChanged', handlerAccount);
      window.ethereum.on('chainChanged', handlerChain);
    }
  }, []);

  return <>{children}</>;
};

export default MetamaskProvider;

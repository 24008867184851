import styled from 'styled-components';
import {ReactComponent as SwapImage} from '../../assets/swapArrows.svg';
import {fadeout, ping} from '../../ui-kit/animations';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 90%;
  margin-top: 40px;

  @media (min-width: 1200px) {
    width: 95%;
  }
`;

export const NetworkBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  background-color: rgb(39, 63, 82);
  padding: 0;
  width: 476px;
  height: 360px;
  @media (max-width: 1465px) {
    width: 100%;
    min-width: 300px;
  }
`;

export const InnerNetworkBlock = styled.div`
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
`;

export const SwapBlock = styled.div<{$loading: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  position: relative;
  height: 150px;
  background-color: rgb(45, 69, 87);
  @media (max-width: 1465px) {
    min-width: 100px;
    border: 1px solid rgb(45, 69, 87);
  }

  ::before {
    content: '';
    ${({$loading: loading}) => (!loading ? 'display: none;' : '')}
    position: absolute;
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: rgb(45, 69, 87);
    animation: ${ping} infinite 1.5s linear;
  }

  ::after {
    content: '';
    ${({$loading: loading}) => (!loading ? 'display: none;' : '')}
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-color: rgb(45, 69, 87);
    animation: ${ping} infinite 1.5s linear;
  }
`;

export const SwapButton = styled(SwapImage)<{$isDisabled?: boolean}>`
  cursor: pointer;
  transition: transform 0.5s;
  &:hover {
    ${({$isDisabled}) => ($isDisabled ? '' : 'transform: rotate(-180deg);')}
  }
  path {
    ${({$isDisabled}) => ($isDisabled ? 'fill: darkgray' : '')}
  }
  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
`;

export const LoadingButton = styled(SwapImage)`
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
`;

export const NetworkRow = styled.div`
  display: flex;
  align-items: center;
`;

export const NetworkImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 69px;
  width: 69px;
  border-radius: 50%;
  margin-right: 18px;
  @media (max-width: 1300px) {
    margin-right: 10px;
  }
`;

export const WaterwallIcon = styled.img`
  width: 44px;
  height: 44px;
`;

export const InputWrapper = styled.div`
  display: flex;
  padding: 0 30px;
  background-color: #213748;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-top: 29px;
  align-items: center;
  border-radius: 10px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const TransferButton = styled.input`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #008eee;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  padding: 0px 40px;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 10px;
  border: none;
  margin-bottom: 75px;
  transition: background-color 0.5s ease;
  outline: none;
  animation: ${fadeout} 0.3s linear;
  height: 60px;
  &:hover {
    background-color: #00b8ee;
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  text-align: left;
  width: 100%;
  & > span {
    margin-right: 5px;
  }
`;

export const WaterAddressWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #102636;
  padding: 10px;
  box-sizing: border-box;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-align: left;
  justify-content: center;
  color: rgb(255, 255, 255);
  padding: 20px;
  width: 60%;
  background: rgb(25, 47, 64);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  animation: ${fadeout} 0.3s linear;
`;

export const BackButton = styled.button`
  padding: 10px 10px 10px 0;
  background: none;
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  top: 90px;
  left: 80px;
  cursor: pointer;
  border: none;
  :hover {
    & span {
      text-decoration: underline;
    }
  }

  & > span:first-child {
    position: relative;
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 8px;
      height: 8px;
      border-top: 1px rgba(255, 255, 255, 0.87) solid;
      border-left: 1px rgba(255, 255, 255, 0.87) solid;
      transform: rotate(-45deg) translateY(-1px);
    }
  }

  @media (max-width: 650px) {
    position: fixed;
    padding: 10px;
    background-color: #102636;
    top: 87vh;
    padding-left: 20px;
  }
`;

export const ScaleWrapper = styled.div`
  transform: scale(-1, 1);
  margin-bottom: 10px;
`;

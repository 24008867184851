import React from 'react';
import {Login} from '../../components/Login';
import {useLogin} from '../../hooks/Auth';

export const LoginContainer = () => {
  const {treatmentChains, setError, errorState, activeAddress, requestAccounts} = useLogin();
  return (
    <Login
      setError={setError}
      errorState={errorState}
      activeAddress={activeAddress}
      requestAccounts={requestAccounts}
      treatmentChains={treatmentChains}
    />
  );
};

import React from 'react';
import {LinkToHash} from './styles';
import {ReactComponent as ArrowLink} from '../../assets/arrowLink.svg';

export const HashLink = ({href}: {href: string}) => {
  return (
    <LinkToHash href={href}>
      <ArrowLink />
    </LinkToHash>
  );
};

import Web3 from 'web3';
import {NETWORKS} from '../../constants/env';
import {RequestedPermissionst} from './types';

export const checkPermission = async (): Promise<boolean> => {
  return await window.ethereum
    .request({
      method: 'wallet_getPermissions',
    })
    .then((permissions: [] | Array<RequestedPermissionst>) => {
      if (permissions.length > 0) {
        return true;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

export const checkChain = async (): Promise<string | undefined> => {
  return (await window.ethereum?.request({method: 'eth_chainId'})) || undefined;
};

export const getAccounts = async (): Promise<string> => {
  if (await checkPermission()) {
    const accounts = await window.ethereum.request({method: 'eth_accounts'});
    return accounts.length > 0 ? accounts[0] : '';
  }
  return '';
};

export const gasAssessmentContract = async (sender: string, contractAddress: string) => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  const gasUsed = await web3.eth.estimateGas({
    to: contractAddress,
    from: sender,
  });
  return gasUsed;
};

export const switchNetwork = async (chainId: string): Promise<boolean> => {
  let statusTransaction = false;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{chainId: chainId}],
    });
    statusTransaction = true;
  } catch (switchError: any) {
    const net = NETWORKS?.find((item) => Web3.utils.toHex(item.ChainID) === chainId);
    if (!net) statusTransaction = false;
    if (switchError.code === 4902) {
      statusTransaction = await addNetwork({
        chainId,
        chainName: net?.chainName || '',
        rpcUrl: net?.rpcUrl || '',
        blockExplorerUrl: net?.blockExplorerUrl || '',
        symbol: net?.symbol || '',
      });
    }
  }
  return statusTransaction;
};

export const addNetwork = async ({
  blockExplorerUrl,
  chainId,
  chainName,
  rpcUrl,
  symbol,
}: {
  chainId: string;
  chainName: string;
  rpcUrl: string;
  blockExplorerUrl: string;
  symbol: string;
}) => {
  let statusTransaction;
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: Web3.utils.toHex(chainId),
          chainName: `${chainName}`,
          nativeCurrency: {
            name: symbol,
            symbol: symbol,
            decimals: 18,
          },
          rpcUrls: [rpcUrl],
          blockExplorerUrls: [blockExplorerUrl],
        },
      ],
    });
    statusTransaction = true;
  } catch (switchError: any) {
    statusTransaction = false;
  }
  return statusTransaction;
};

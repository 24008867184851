import {SetterOrUpdater} from 'recoil';

const checkAmountValid = (value: number) => {
  return value > 0;
};

export const validateBalance = (amount: string, balance: string, setError: SetterOrUpdater<string | null>) => {
  if (!amount) return setError('');
  if (checkAmountValid(Number(amount))) {
    if (Number(amount) <= Number(balance)) {
      setError('');
    } else {
      setError('Insufficient balance');
    }
  } else {
    setError('Wrong value');
  }
};

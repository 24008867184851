import {NetworkT} from '../hooks/Metamask/types';

declare global {
  interface Window {
    _env_: {
      REACT_APP_NETWORKS: string | undefined;
      REACT_APP_GAS_LIMIT: number | undefined;
      REACT_APP_WANSCAN_URL: string | undefined;
    };
  }
}

export const NETWORKS: NetworkT[] | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_NETWORKS
    ? JSON.parse(window._env_.REACT_APP_NETWORKS || '')
    : process.env.REACT_APP_NETWORKS
    ? JSON.parse(process.env.REACT_APP_NETWORKS || '')
    : [];

export const GAS_LIMIT =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_GAS_LIMIT
    ? Number(window._env_.REACT_APP_GAS_LIMIT)
    : Number(process.env.REACT_APP_GAS_LIMIT || '');

export const WANSCAN_URL =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_WANSCAN_URL
    ? window._env_.REACT_APP_WANSCAN_URL
    : process.env.REACT_APP_WANSCAN_URL;

import React, {PropsWithChildren} from 'react';
import {useLayout, useLogout} from '../../hooks/Auth';
import {MainLayout} from '../../ui-kit/Layout';

export const LayoutContainer = ({children}: PropsWithChildren) => {
  const {userAddress} = useLayout();
  const {logOut} = useLogout();
  return (
    <MainLayout logOut={logOut} userAddress={userAddress}>
      {children}
    </MainLayout>
  );
};

import React from 'react';
import {
  Wrapper,
  NetworkBlock,
  SwapBlock,
  SwapButton,
  NetworkRow,
  NetworkImageWrapper,
  BalanceWrapper,
  InputWrapper,
  ScaleWrapper,
  ErrorWrapper,
  TransferButton,
  WaterwallIcon,
  InnerNetworkBlock,
  WaterAddressWrapper,
  BackButton,
  LoadingButton,
} from './styles';
import {Text} from '../../ui-kit/Typography/Text';
import {NETWORKS, WANSCAN_URL} from '../../constants/env';
import SelectInput from '../../ui-kit/Select';
import {NETWORK_ICONS} from '../../constants/common';
import {ScanLinkWrapper} from '../Login/styles';
import {Input} from '../../ui-kit/Input';
import {getTokenUrl, getTransactionUrl} from '../../helper/url';
import {Devider} from '../../ui-kit/Devider';
import {HashLink} from '../../ui-kit/HashLink';
import {Link} from '../../ui-kit/Link';
import {HomePropsT} from './types';

const options =
  NETWORKS?.map((item) => ({
    label: item.chainName,
    value: item.ChainID,
  })) || [];

export const HomePage: React.FC<HomePropsT> = ({
  amount,
  changeAmount,
  firstNetwork,
  swapNetwork,
  secondNetwork,
  transfer,
  changeNetwork,
  transaction,
  balance,
  errorState,
  closeViewMode,
}) => {
  const handlerChangeNetwork = (isFirstNetwork: boolean) => (chainId: string) => {
    changeNetwork(chainId, isFirstNetwork);
  };

  const firstNetworkIcon = NETWORK_ICONS.find((el) => el.chainIds.includes(firstNetwork.ChainID))?.icon;
  const secondNetworkIcon = NETWORK_ICONS.find((el) => el.chainIds.includes(secondNetwork.ChainID))?.icon;

  const swapDisabled =
    firstNetwork?.notAllowedChainIds?.includes(secondNetwork.ChainID) ||
    secondNetwork?.notAllowedChainIds?.includes(firstNetwork.ChainID);

  const isSent = transaction.loading || transaction.reciveHash;

  return (
    <>
      {(transaction.loading || transaction.reciveHash) && (
        <BackButton onClick={closeViewMode}>
          <Text size={18}>Back</Text>
        </BackButton>
      )}
      <Wrapper>
        <NetworkBlock>
          <InnerNetworkBlock>
            <Text size={24}>From</Text>
            <Devider />
            <NetworkRow>
              <NetworkImageWrapper>
                {firstNetworkIcon ? <WaterwallIcon src={firstNetworkIcon} /> : null}
              </NetworkImageWrapper>
              {isSent ? (
                <Text size={20}>{firstNetwork.chainName}</Text>
              ) : (
                <SelectInput
                  options={options?.filter((el) => el?.value !== firstNetwork.ChainID) || []}
                  value={options?.find((el) => el.value === firstNetwork.ChainID)?.label || ''}
                  onChange={handlerChangeNetwork(true)}
                />
              )}
            </NetworkRow>
            <InputWrapper>
              {isSent ? (
                <>
                  <Text size={24}>{transaction.sendHash || 'loading...'}</Text>
                  {transaction.sendHash && (
                    <HashLink href={getTransactionUrl(firstNetwork?.blockExplorerUrl, transaction.sendHash)} />
                  )}
                </>
              ) : (
                <Input onChange={changeAmount} value={amount} placeholder={'0.00'} />
              )}
            </InputWrapper>
            <BalanceWrapper>
              {!isSent ? (
                <>
                  <Text size={16} color="#2EEDFF">
                    Available balance:
                  </Text>
                  <Text size={16}>{balance} Water</Text>
                </>
              ) : (
                <>
                  <Text size={16} color="#2EEDFF">
                    Confirmations:
                  </Text>
                  <Text size={16}>{transaction.confirmation}</Text>
                </>
              )}
            </BalanceWrapper>
          </InnerNetworkBlock>
          {!firstNetwork?.isNative && (
            <WaterAddressWrapper>
              <Text size={14}>Water Address: {firstNetwork?.WWater}</Text>
              <HashLink href={getTokenUrl(firstNetwork?.blockExplorerUrl, firstNetwork?.WWater)} />
            </WaterAddressWrapper>
          )}
        </NetworkBlock>
        <SwapBlock $loading={transaction.loading}>
          <ScaleWrapper>
            {transaction.loading ? (
              <LoadingButton />
            ) : (
              <SwapButton $isDisabled={swapDisabled} onClick={swapDisabled ? undefined : swapNetwork} />
            )}
          </ScaleWrapper>
          {isSent && <Text size={16}>{amount}</Text>}
        </SwapBlock>
        <NetworkBlock>
          <InnerNetworkBlock>
            <Text size={24}>To</Text>
            <Devider />
            <NetworkRow>
              <NetworkImageWrapper>
                {secondNetworkIcon ? <WaterwallIcon src={secondNetworkIcon} /> : null}
              </NetworkImageWrapper>
              {isSent ? (
                <Text size={20}>{secondNetwork.chainName}</Text>
              ) : (
                <SelectInput
                  options={options?.filter(
                    (el) =>
                      !firstNetwork?.notAllowedChainIds?.includes(el.value) &&
                      el.value !== firstNetwork.ChainID &&
                      el?.value !== secondNetwork?.ChainID,
                  )}
                  value={options?.find((el) => el.value === secondNetwork.ChainID)?.label || ''}
                  onChange={handlerChangeNetwork(false)}
                />
              )}
            </NetworkRow>
            {isSent && (
              <InputWrapper>
                <Text size={24}>{transaction.reciveHash || 'loading...'}</Text>
                {transaction.reciveHash && (
                  <HashLink href={getTransactionUrl(secondNetwork?.blockExplorerUrl, transaction.reciveHash)} />
                )}
              </InputWrapper>
            )}
          </InnerNetworkBlock>
          {!secondNetwork?.isNative && (
            <WaterAddressWrapper>
              <Text size={14}>Water Address: {secondNetwork?.WWater}</Text>
              <HashLink href={getTokenUrl(secondNetwork?.blockExplorerUrl, secondNetwork?.WWater)} />
            </WaterAddressWrapper>
          )}
        </NetworkBlock>
      </Wrapper>
      {transaction.isProcessing && (
        <ScanLinkWrapper>
          <Link target="_blank" href={`${WANSCAN_URL}/tx/${transaction.sendHash}`}>
            <Text>Check Explorer for details</Text>
          </Link>
        </ScanLinkWrapper>
      )}
      {errorState && <ErrorWrapper>{errorState}</ErrorWrapper>}
      {!errorState && !!Number(amount) && !isSent && (
        <TransferButton type={'button'} value={'Transfer'} onClick={transfer} />
      )}
    </>
  );
};

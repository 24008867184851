import styled from 'styled-components';

export const Wrapper = styled.header`
  width: 50px;
  height: 20px;
  border: 1px solid #fff;
  margin: 0 10px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  :hover {
    background-color: rgb(45, 69, 87);
  }
`;

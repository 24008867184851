import React from 'react';
import Routes from './Routes';
import {LayoutContainer} from './containers/Layout';
import RecoilProvider from './prodivers/Recoil';
import MetamaskProvider from './prodivers/Metamask';

const App: React.FC = () => {
  return (
    <RecoilProvider>
      <MetamaskProvider>
        <LayoutContainer>
          <Routes />
        </LayoutContainer>
      </MetamaskProvider>
    </RecoilProvider>
  );
};

export default App;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  width: 400px;
  padding: 10px 15px;
  background-color: rgb(39, 63, 82);
  @media (max-width: 480px) {
    width: 80%;
    padding: 10px 5%;
  }
`;

export const ModalBackground = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 25px;
  align-items: flex-end;
  width: 100%;
`;

export const CloseImage = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const ConnectButton = styled.input`
  text-align: center;
  background: rgb(45, 69, 87);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 20px;
  word-wrap: break-word;
  width: 70%;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 10px;
  border: none;
  margin-bottom: 30px;
  transition: background-color 0.5s ease;
  outline: none;
  @media (max-width: 500px) {
    width: 80%;
    padding: 16px 5%;
    font-size: 16px;
  }
  &:hover {
    background-color: #55baff;
  }
  &:active {
    background-color: rgba(16, 38, 54, 0.6);
  }
`;

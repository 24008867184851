import React, {PropsWithChildren, useEffect, useState} from 'react';
import {RecoilRoot} from 'recoil';
import {useHistory} from 'react-router-dom';
import {useCheckConnect} from '../../hooks/Auth';
import {route} from '../../constants/route';
import {session} from '../../states/Session';
import {checkChain} from '../../hooks/Metamask';

const RecoilProvider: React.FC<PropsWithChildren> = ({children}) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [address, setAddress] = useState<string>('');
  const [chainId, setChainId] = useState<string>('');

  useEffect(() => {
    checkChain().then((result) => {
      setChainId(result ? result : '');
      useCheckConnect().then((address) => {
        setAddress(address);
        setLoading(false);
        if (address.length === 0) {
          history.push(route.login);
        } else {
          history.push(route.base);
        }
      });
    });
  }, []);

  if (loading) return null;
  return (
    <RecoilRoot
      initializeState={({set}) => {
        set(session, {
          error: '',
          address: address,
          chainId: chainId,
        });
      }}>
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;

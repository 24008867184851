import bnb from '../assets/bnb.png';
import eth from '../assets/ethernet.png';
import waterfall from '../assets/waterfall.png';

export const MAIN_STIE_LINK = 'https://waterfall.network/';

export const NETWORK_ICONS = [
  {
    chainIds: ['56', '97'],
    icon: bnb,
  },
  {
    chainIds: ['11155111', '1'],
    icon: eth,
  },
  {
    chainIds: ['1501869', '181'],
    icon: waterfall,
  },
];

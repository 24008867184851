import {ChangeEventHandler, useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {GAS_LIMIT, NETWORKS} from '../../constants/env';
import Web3 from 'web3';
import {errors} from '../../constants/errors';
import {AbiItem} from 'web3-utils';
import * as WWaterContract from '../../constants/contracts/WWater-contract.json';
import * as WBridgeContract from '../../constants/contracts/WBridge-contract.json';
import * as WaterBridgeContract from '../../constants/contracts/WaterBridge-contract.json';
import {validateBalance} from '../../helper/validation';
import {NetworkT} from '../Metamask/types';
import {WatcherT} from './types';
import {getApiUrl} from '../../helper/transaction';
import {address, chainId, error} from '../../states/Session';
import {switchNetwork} from '../Metamask';

const tokenContractJSON = JSON.parse(JSON.stringify(WWaterContract)).default;
const bridgeContractJSON = JSON.parse(JSON.stringify(WBridgeContract)).default;
const waterBridgeContractJSON = JSON.parse(JSON.stringify(WaterBridgeContract)).default;

const contrancts = {
  token: tokenContractJSON,
  bridge: bridgeContractJSON,
  bridgeNative: waterBridgeContractJSON,
};

export type TransactionStatusT = {
  loading: boolean;
  sendHash: string;
  reciveHash: string;
  confirmation: number;
  isProcessing: boolean;
};

const initState = {
  loading: false,
  sendHash: '',
  reciveHash: '',
  confirmation: 0,
  isProcessing: false,
};

const speedUpSuggestion = 15;

export const useTransfer = () => {
  const userAddress = useRecoilValue(address);
  const web3 = new Web3(Web3.givenProvider);
  const [currentChainId, setChainId] = useRecoilState(chainId);
  const [errorState, setError] = useRecoilState(error);

  const [firstNetwork, setFirstNetwork] = useState<NetworkT>(
    NETWORKS?.find((el) => web3.utils.toHex(el.ChainID || '') === currentChainId) as NetworkT,
  );
  const [secondNetwork, setSecondNetwork] = useState<NetworkT>(
    NETWORKS?.find(
      (el) =>
        web3.utils.toHex(el.ChainID || '') !== currentChainId &&
        !NETWORKS?.find((el) => web3.utils.toHex(el.ChainID || '') === currentChainId)?.notAllowedChainIds?.includes(
          el?.ChainID,
        ),
    ) as NetworkT,
  );
  const [balance, setBalance] = useState<string>('');
  const [amount, setAmount] = useState<string>('0.00');
  const [transaction, setTransaction] = useState<TransactionStatusT>(initState);

  const handlerChangeAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (/^\d+$/.test(e.target.value.replace('.', '')) || e.target.value === '') {
      validateBalance(e.target.value, balance, setError);
      setAmount(e.target.value);
    }
  };

  const handlerSetNetwork = async (chainId: string, isFirstNet: boolean) => {
    if (isFirstNet) {
      const statusTransaction = await switchNetwork(web3.utils.toHex(chainId));
      if (statusTransaction) {
        setChainId(web3.utils.toHex(chainId || ''));
        const network = NETWORKS?.find((el) => el.ChainID === chainId) as NetworkT;
        setFirstNetwork(network);
        setSecondNetwork(
          NETWORKS?.find(
            (el) => el.ChainID !== chainId && !network?.notAllowedChainIds?.includes(el?.ChainID),
          ) as NetworkT,
        );
        setError('');
      } else {
        setError(errors.userReject);
      }
    } else {
      setSecondNetwork(NETWORKS?.find((el) => el.ChainID === chainId) as NetworkT);
    }
  };

  const treatmentChains = async () => {
    try {
      const firstNetwork = NETWORKS?.find((item) => item);
      const secondNetwork = NETWORKS?.find((item) => item?.ChainID !== firstNetwork?.ChainID);
      if (!firstNetwork || !secondNetwork) return false;
      const res = await switchNetwork(web3.utils.toHex(NETWORKS?.find((item) => item)?.ChainID || ''));
      if (!res) return false;
      setFirstNetwork(firstNetwork);
      setSecondNetwork(secondNetwork);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const getBalance = async () => {
      try {
        let userBalacnce = '0';
        if (firstNetwork?.isNative) {
          userBalacnce = await web3.eth.getBalance(userAddress || '');
        } else {
          const contract = new web3.eth.Contract(contrancts.token as AbiItem[], firstNetwork?.WWater);
          userBalacnce = await contract.methods.balanceOf(userAddress).call({
            from: userAddress,
          });
        }
        setBalance(parseFloat(web3.utils.fromWei(userBalacnce, 'ether'))?.toFixed(2) || '0');
      } catch (error) {
        console.log(error);
        setBalance('0');
      }
    };
    if (firstNetwork?.ChainID && userAddress) {
      getBalance();
    }
  }, [firstNetwork?.ChainID, userAddress]);

  const closeViewMode = () => {
    setAmount('0.00');
    setTransaction(initState);
  };

  const swapNetwork = async () => {
    const newFirst = secondNetwork;
    const newSecond = firstNetwork;
    if (!newFirst || !newSecond) return;
    const statusTransaction = await switchNetwork(web3.utils.toHex(newFirst?.ChainID || ''));
    if (statusTransaction) {
      setTransaction(initState);
      setChainId(web3.utils.toHex(newFirst?.ChainID));
      setFirstNetwork(newFirst);
      setSecondNetwork(newSecond);
      setError('');
    } else {
      setError(errors.userReject);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    const checkTx = async () => {
      try {
        const data = await fetch(getApiUrl(transaction.sendHash));
        if (!data.ok) {
          setTransaction((prev) => ({...prev, confirmation: prev.confirmation + 1}));
          return;
        }
        const result = (await data.json())?.[0] as WatcherT;
        if (!result?.status) {
          setTransaction((prev) => {
            prev.confirmation === speedUpSuggestion && setError(errors.speedUp);
            return {...prev, confirmation: prev.confirmation + 1};
          });
          return;
        }
        if (result?.status === 'Processing' && !transaction?.isProcessing) {
          errorState && setError('');
          setTransaction((prev) => ({...prev, isProcessing: true, confirmation: prev.confirmation + 1}));
          return;
        }
        if (result?.status !== 'Success') {
          setTransaction((prev) => ({...prev, confirmation: prev.confirmation + 1}));
          return;
        }
        errorState && setError('');
        setTransaction((prev) => ({...prev, loading: false, reciveHash: result.receiveTxHash}));
        intervalId && clearInterval(intervalId);
      } catch (error) {
        console.log(error);
        setTransaction((prev) => ({...prev, confirmation: prev.confirmation + 1}));
      }
    };

    if (transaction.sendHash) {
      intervalId = setInterval(checkTx, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [transaction.sendHash]);

  const transfer = async () => {
    try {
      setTransaction((prev) => ({...prev, loading: true}));
      const contract = firstNetwork?.isNative
        ? new web3.eth.Contract(contrancts.bridgeNative as AbiItem[], firstNetwork?.WBridge)
        : new web3.eth.Contract(contrancts.bridge as AbiItem[], firstNetwork?.WBridge);

      const esitmateGas = await contract.methods.estimateFee(secondNetwork?.WMBChainID, GAS_LIMIT).call();
      const transfer = (await new Promise(async (resolve, reject) => {
        try {
          await contract.methods
            .send(secondNetwork?.WMBChainID, secondNetwork?.WBridge, userAddress, web3.utils.toWei(amount), GAS_LIMIT)
            .send({
              from: userAddress,
              value: firstNetwork?.isNative
                ? web3.utils.toBN(web3.utils.toWei(amount)).add(web3.utils.toBN(esitmateGas))?.toString()
                : esitmateGas,
            })
            .on('transactionHash', (txHash: string) => {
              resolve(txHash);
            });
        } catch (e) {
          reject(e);
        }
      })) as string;
      setTransaction((prev) => ({...prev, sendHash: transfer}));
    } catch (error) {
      console.log(error);
      setTransaction((prev) => ({...prev, loading: false, sendHash: '', reciveHash: ''}));
      typeof (error as unknown as Error)?.message === 'string' && setError((error as unknown as Error)?.message);
    }
  };

  return {
    firstNetwork,
    secondNetwork,
    errorState,
    swapNetwork,
    handlerChangeAmount,
    amount,
    transfer,
    handlerSetNetwork,
    closeViewMode,
    transaction,
    balance,
    treatmentChains,
  };
};

import {checkMetaMask} from '../../helper/metamask';
import {errors} from '../../constants/errors';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {NETWORKS} from '../../constants/env';
import Web3 from 'web3';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/route';
import {address, chainId, error} from '../../states/Session';
import {checkChain, getAccounts} from '../Metamask';

export const useCheckConnect = async () => {
  const chainId = await checkChain();
  if (!chainId) {
    return '';
  }
  if (!checkMetaMask()) {
    return '';
  }
  return await getAccounts();
};

export const useLogin = () => {
  const [activeAddress, setActiveAddress] = useRecoilState(address);
  const [errorState, setError] = useRecoilState(error);
  const setChainId = useSetRecoilState(chainId);

  const treatmentChains = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: Web3.utils.toHex(NETWORKS?.find((item) => item)?.ChainID || '')}],
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const requestAccounts = async (): Promise<string> => {
    if (!checkMetaMask()) {
      return errors.metamask;
    }
    const metamaskNetwork = await checkChain();
    if (!metamaskNetwork) {
      return errors.metamask;
    }
    setChainId(metamaskNetwork);
    try {
      let userAddress = activeAddress;
      const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      if (!userAddress && accounts.length) {
        setActiveAddress(accounts[0]);
        userAddress = accounts[0];
      }
      if (accounts) {
        if (accounts.length && userAddress && userAddress.length) {
          return '';
        } else {
          return 'Check your network connection';
        }
      } else {
        return errors.userReject;
      }
    } catch (e: any) {
      return e?.message || errors.userReject;
    }
  };
  return {
    requestAccounts,
    treatmentChains,
    activeAddress,
    setError,
    errorState,
  };
};

export const useLogout = () => {
  const setActiveAddress = useSetRecoilState(address);
  const setError = useSetRecoilState(error);
  const setChainId = useSetRecoilState(chainId);
  const history = useHistory();
  const logOut = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_revokePermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      setChainId('');
      setActiveAddress('');
      setError('');
      history.push(route.login);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    logOut,
  };
};

export const useLayout = () => {
  const userAddress = useRecoilValue(address);

  return {
    userAddress,
  };
};

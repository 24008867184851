import styled from 'styled-components';
import {fadeout} from '../animations';

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 388px;
  height: 52px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: calc(100% - 39px);
  align-items: center;
  height: 52px;
  padding: 0 15px 0 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: rgba(48, 76, 98, 1);
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  z-index: 2;
  color: #fff;
  font-size: 20px;
  border: none;
  font-weight: 400;
`;

export const DownArrow = styled.img`
  width: 15px;
  height: 8px;
  margin-left: 16px;
  margin-right: 5px;
  transform: ${(props) => (props.theme.status ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const OptionsNetWrapper = styled.div`
  display: ${(props) => (props.theme.status ? 'flex' : 'none')};
  position: absolute;
  background: rgba(44, 70, 91, 0.59);
  border-radius: 10px;
  flex-direction: column;
  padding-top: 37px;
  top: 26px;
  width: 100%;
  z-index: 1;
  & > div:last-child {
    &:hover {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  animation: ${fadeout} 0.2s linear;
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: 15px;
  width: calc(100% - 24px);
  line-height: 22px;
  font-weight: 400;
  height: 100%;
  padding-left: 24px;
  padding-bottom: 7px;
  &:hover {
    background: rgba(44, 70, 91, 1);
  }
`;

import styled from 'styled-components';
import {Text} from '../Typography/Text';
import {Link} from '../Link';

export const MainLayoutContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: #233848;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text).attrs({
  color: 'blue',
  size: 24,
})`
  display: inline-block;
  margin-bottom: 40px;
  @media (max-width: 425px) {
    margin-bottom: 20px;
  }
`;

export const FooterContent = styled(Text)`
  padding: 0 60px;
  width: calc(100% - 120px);
`;

export const ContentMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  margin: 0;
`;

export const Footer = styled.footer`
  display: flex;
  position: fixed;
  text-align: center;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: #102636;
`;

export const HeaderLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: 16px;

  &:hover {
    & span {
      text-decoration: underline;
    }
  }

  @media (max-width: 560px) {
    :first-child {
      margin-right: 16px;
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  background-color: #102636;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  margin: 0 auto;
  padding: 10px 60px;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

export const AddressHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #233848;
  gap: 10px;
`;

export const FooterLink = styled(Link)`
  color: rgb(46, 237, 255);
`;

import {atom, selector} from 'recoil';
import {SessionStatet} from './types';

export const session = atom<SessionStatet>({
  key: 'session',
  default: undefined,
});

export const address = selector<string | null>({
  key: 'address',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.address;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, address: newValue});
  },
});

export const error = selector<string | null>({
  key: 'error',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.error;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, error: newValue});
  },
});

export const chainId = selector<string | null>({
  key: 'chainId',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.chainId;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, chainId: newValue});
  },
});

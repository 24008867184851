import styled from 'styled-components';

export const InputComponent = styled.input`
  box-sizing: border-box;
  height: 34px;
  background-color: #213748;
  width: 100%;
  outline: none;
  color: #fff;
  margin: 0;
  border: 0;
  display: flex;
  font-size: 24px;
  align-items: center;
  font-family: Roboto, sans-serif;
  &::placeholder {
    size: 18px;
    color: #fff;
  }
`;
